import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import {RestApiService} from "../services/RestApiService";
import {ContactRequest} from "../models/contact";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

  contactForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    message: new FormControl(''),
  });

  constructor(private apiService: RestApiService) {
  }

  ngOnInit() {
  }

  onSubmit() {
    let contactReq = new ContactRequest();
    contactReq.email = this.contactForm.value.email;
    contactReq.name = this.contactForm.value.name;
    contactReq.message = this.contactForm.value.message;
    this.apiService.contactRequest(contactReq).subscribe(m => {
      window.alert("Mesajul a fost trimis cu succes! Vă vom contacta cât de curând, în caz contrar vă rugăm să ne contactați și telefonic");
    });
  }
}
