import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styles: []
})
export class BlogComponent implements OnInit {

  posts = [];

  constructor() {
    this.posts = [{
      "title": 'The timeless architecture is the ideal stage for any event',
      "author": 'Caroline Glover',
      'date': '5 Sep 2015',
      'description': 'Our diverse conference rooms and halls can make a perfect place for virtually any type of event. So be it a wedding or a business meeting - we\'ve got your event covered!'
    }];
  }

  ngOnInit() {
  }

}
