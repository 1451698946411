<app-header headerClass="header-3" [hasBookingDialog]="false"></app-header>
<main class="page-content">
    <!--Section Google Map-->
    <section class="margin-top-76 relative">
      <!-- RD Google Map-->
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2805.472710299801!2d23.232196515552587!3d45.31907607909945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb79f4fbff3587f54!2sCabana+Smile!5e0!3m2!1sen!2sro!4v1532272636809" width="100%" height="660" marginheight="86" frameborder="0" style="border:0" allowfullscreen></iframe>

      <div class="address-block">
        <h4 class="tt-u">Smile Apres Ski</h4>
        <address class="address" i18n="address text|Full location address@@addressText">36 Strada Soarelui, Trup 3, Straja </address><a href="callto:0040784776041">+40 (0)784 776 041</a><br><a href="mailto:contact@cabanasmile.ro">contact@cabanasmile.ro</a>
      </div>
    </section>
    <section class="bg-image bg-image-6">
      <section class="section-md-top-292 section-md-bottom-110 section-top-50">
        <div class="shell">
          <div class="range">
            <div class="cell-lg-10 cell-lg-preffix-1">
              <div class="img-block-2 img-block-2-mod-2">
                <div class="img-block-2-inner">
                  <h3 class="fw-eb" i18n="contact text1|Text1@@contactText1">Comments, Questions? Fill out the form below!</h3>
                  <div class="offset-top-31">
                    <!-- RD Mailform-->
                    <form data-form-output="form-output-global" data-form-type="contact" (ngSubmit)="onSubmit()" [formGroup]="contactForm" class="rd-mailform text-left rd-mailform-2">
                      <div class="range range-sm-bottom">
                        <div class="cell-sm-6">
                          <div class="form-group">
                            <label for="contact-name" class="form-label" i18n="contact name|Enter your name@@contactNameLabel">Enter your name</label>
                            <input id="contact-name" formControlName="name" type="text" name="name" data-constraints="@Required" class="form-control">
                          </div>
                          <div class="form-group offset-top-45">
                            <label for="contact-email1" class="form-label" i18n="enter email|Enter your email@@enterEmailText">Enter your e-mail</label>
                            <input id="contact-email1" formControlName="email" type="email" name="email" data-constraints="@Email @Required" class="form-control">
                          </div>
                        </div>
                        <div class="cell-sm-6 offset-sm-top-43 offset-top-60">
                          <div class="form-group">
                            <label for="contact-message" class="form-label form-label-2" i18n="contact message|Enter your message@@contactMessageLabel">Enter your Message</label>
                            <textarea id="contact-message" formControlName="message" name="message" data-constraints="@Required" class="form-control"></textarea>
                          </div>
                        </div>
                        <div class="cell-sm-6 offset-top-43">
                          <button type="submit" class="btn btn-black btn-md-3" i18n="contact submit|Submit message button@@contactSubmitMessage">Send message</button>
                        </div>
                        <div class="cell-sm-6">
                          <p class="required" i18n="fields required|Complete all required fields@@allFieldsRequired">*Please complete all required fields</p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Page Footer-->
      <app-footer></app-footer>
    </section>
  </main>
