import { Component, Input, OnInit, LOCALE_ID, Inject } from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  languages = [
    { code: 'ro', label: 'RO'},
    { code: 'en', label: 'EN'},
  ];

  @Input() headerClass = "header-default";
  @Input() hasBookingDialog = false;
  @Input() selectedLocale = "";

  constructor(@Inject(LOCALE_ID) protected localeId: string) {
    this.selectedLocale = localeId
  }

  ngOnInit() {
  }

  setLanguage(event) {
    console.log("plm");
    console.log(event)
  }

}
