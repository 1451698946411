import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HeaderReservationComponent } from './header-reservation/header-reservation.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { RoomsComponent } from './rooms/rooms.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactComponent } from './contact/contact.component';
import { GalleryDetailComponent } from './gallery-detail/gallery-detail.component';
import {Angular2ImageGalleryModule} from "angular2-image-gallery";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BlogComponent } from './blog/blog.component';
import {RestApiService} from "./services/RestApiService";

// import { HashLocationStrategy, LocationStrategy } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderReservationComponent,
    FooterComponent,
    HomeComponent,
    RoomsComponent,
    GalleryComponent,
    ContactComponent,
    GalleryDetailComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Angular2ImageGalleryModule,
    FormsModule,
    ReactiveFormsModule
  ],
  // providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  providers: [RestApiService],
  bootstrap: [AppComponent],
  entryComponents: [HeaderComponent, HeaderReservationComponent]
})
export class AppModule { }
