import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-gallery-detail',
  templateUrl: './gallery-detail.component.html'
})
export class GalleryDetailComponent implements OnInit {

  galleryName: string;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.galleryName = this.route.snapshot.params.galleryName;


  }

}
