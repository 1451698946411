<app-header headerClass="header-3" [hasBookingDialog]="false"></app-header>
<div class="bg-image bg-image-1">
  <main class="page-content txt-white">
    <!--Section Discover the possibilities-->
    <section class="section-md-bottom-62 section-top-88">
      <div class="shell">
        <h5 i18n="gallery text1|Gallery Text 1@@galleryText1">Enjoy a perfect stay with us!</h5>
        <h2 i18n="photos videos label|Photos & Videos@@photosVideosLabel">Photos & Videos</h2>
        <div class="range">
          <div class="cell-md-4 cell-sm-6">
            <div class="thumbnail-custom thumbnail-custom-2 thumbnail-custom-2-mod-2">
              <div class="thumbnail-custom-body"><img src="assets/images/cabana_cover_photo.jpg" alt="" width="325" height="166"/>
                <div class="caption">
                  <h6><a routerLink="/gallery/cabana">Cabana</a></h6>
                  <p class="small-2">Pictures say a lot about us</p>
                </div>
              </div>
              <div class="thumbnail-custom-footer">
                <div class="number">02</div><a routerLink="/gallery/cabana" class="btn btn-xs btn-primary btn-yellow-70" i18n="see photos|See Photos@@seePhotosLabel">see photos</a>
              </div>
            </div>
          </div>
          <div class="cell-md-4 cell-sm-6">
            <div class="thumbnail-custom thumbnail-custom-2 thumbnail-custom-2-mod-2">
              <div class="thumbnail-custom-body"><img src="assets/images/redbull_cover_photo.jpg" alt="" width="325" height="166"/>
                <div class="caption">
                  <h6><a routerLink="/gallery/romaniacs">RedBull Romaniacs</a></h6>
                  <p class="small-2">World's best riders were at Straja!</p>
                </div>
              </div>
              <div class="thumbnail-custom-footer">
                <div class="number">01</div><a routerLink="/gallery/romaniacs" class="btn btn-xs btn-primary btn-yellow-70" i18n="see photos|See Photos@@seePhotosLabel">see photos</a>
              </div>
            </div>
          </div>
          <div class="cell-md-4 cell-sm-6">
            <div class="thumbnail-custom thumbnail-custom-2 thumbnail-custom-2-mod-2">
              <div class="thumbnail-custom-body"><img src="assets/images/naturecamp_cover_photo.jpg" alt="" width="325" height="166"/>
                <div class="caption">
                  <h6><a routerLink="/gallery/naturecamp">Naturecamp</a></h6>
                  <p class="small-2">Naturecamp were our guests</p>
                </div>
              </div>
              <div class="thumbnail-custom-footer">
                <div class="number">02</div><a routerLink="/gallery/naturecamp" class="btn btn-xs btn-primary btn-yellow-70" i18n="see photos|See Photos@@seePhotosLabel">see photos</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <!-- Page Footer-->
  <app-footer></app-footer>
</div>
