<app-header headerClass="header-3" [hasBookingDialog]="false"></app-header>
<main class="page-content txt-white">
  <!--Section Find out more-->
  <section class="section-82 section-bottom-33">
    <div class="shell">
      <div class="range range-md-bottom">
        <div class="cell-md-6">
          <h2 class="line-height-92">Find out more <span class="text-italic font-sec fw-b h2 big">about</span><br><span class="text-italic font-sec fw-b h2 big">news</span> <span class="fw-n">and</span> upcoming<br>events <span class="fw-n">of</span> our hotel
          </h2>
        </div>
      </div>
    </div>
  </section>
  <!--Section latest news from our blog-->
  <section class="section-top-58">
    <div class="shell">
      <div class="range">
        <div class="cell-lg-12 cell-sm-6">
          <div class="thumbnail-custom thumbnail-custom-2 thumbnail-custom-2-mod-1">
            <div class="thumbnail-custom-body">
              <div class="unit unit-lg-horizontal unit-spacing-xs">
                <div class="unit-left"><img src="images/media-1-425x253.jpg" alt="" width="425" height="253"/>
                </div>
                <div class="unit-body">
                  <div class="caption">
                    <div class="meta"><span class="fw-b"><a href="post.html">Michelle Dell </a></span> | <span>
                                <time datetime="2016">11 January 2015</time></span>
                    </div>
                    <h5><a href="post.html">Gourmet cuisine from our chef</a></h5>
                    <p class="small-2">Our Gourmet cuisine features a rave variety of dishes for any kind of meal: from cereals, fruits, grains, berries, dairies and cheeses choices for breakfast to different meat and fish dishes and steaks, accompanied by incredible lineup of misc salads and appetizers, and with finish by our Wine Cart and other refreshing hot and cold, alcoholic and N/A drinks! Our seasoned team of chefs and sous chefs is here to add an extra taste to your holiday!</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="thumbnail-custom-footer">
              <div class="number">01</div><a href="post.html" class="btn btn-xs btn-primary btn-yellow-70">read more</a>
            </div>
          </div>
        </div>
        <div class="cell-lg-4 cell-sm-6 offset-top-30 offset-sm-top-0 offset-lg-top-30">
          <div class="thumbnail-custom thumbnail-custom-2">
            <div class="thumbnail-custom-body"><img src="images/about-4-325x166.jpg" alt="" width="325" height="166"/>
              <div class="caption">
                <div class="meta"><span class="fw-b"><a href="post.html">Jeffery Black</a></span> | <span>
                            <time datetime="2016">5 Sep 2015</time></span>
                </div>
                <h5><a href="post.html">The timeless architecture is the ideal stage for any event</a></h5>
                <p class="small-2">Our diverse conference rooms and halls can make a perfect place for virtually any type of event. So be it a wedding or a business meeting - we've got your event covered!</p>
              </div>
            </div>
            <div class="thumbnail-custom-footer">
              <div class="number">02</div><a href="post.html" class="btn btn-xs btn-primary btn-yellow-70">read more</a>
            </div>
          </div>
        </div>
        <div class="cell-lg-4 cell-sm-6 offset-top-30">
          <div class="thumbnail-custom thumbnail-custom-2">
            <div class="thumbnail-custom-body"><img src="images/about-5-325x166.jpg" alt="" width="325" height="166"/>
              <div class="caption">
                <div class="meta"><span class="fw-b"><a href="post.html">Carolyn Glover</a></span> | <span>
                            <time datetime="2016">2 Sep 2015</time></span>
                </div>
                <h5><a href="post.html">SPA &amp; Wellness treatments</a></h5>
                <p class="small-2">Our SPA &amp; Wellness centre can get you rid of virtually any strain or stress! With its treatments varying from massages and scrubs and to saunas and hot tubs, we're sure we can calm down and relax even a hungry dragon!</p>
              </div>
            </div>
            <div class="thumbnail-custom-footer">
              <div class="number">03</div><a href="post.html" class="btn btn-xs btn-primary btn-yellow-70">read more</a>
            </div>
          </div>
        </div>
        <div class="cell-lg-4 cell-sm-6 offset-top-30">
          <div class="thumbnail-custom thumbnail-custom-2">
            <div class="thumbnail-custom-body"><img src="images/about-6-325x166.jpg" alt="" width="325" height="166"/>
              <div class="caption">
                <div class="meta"><span class="fw-b"><a href="post.html">Carl Blair</a></span> | <span>
                            <time datetime="2016">12 Aug 2015</time></span>
                </div>
                <h5><a href="post.html">Summer holiday at French Alps </a></h5>
                <p class="small-2">Summer in French Alps gives you truly unique opportunities for a quality leisure. Explore the local countryside on a bicycle and take great pictures!</p>
              </div>
            </div>
            <div class="thumbnail-custom-footer">
              <div class="number">04</div><a href="post.html" class="btn btn-xs btn-primary btn-yellow-70">read more</a>
            </div>
          </div>
        </div>
        <div class="cell-lg-4 cell-sm-6 offset-top-30">
          <div class="thumbnail-custom thumbnail-custom-2">
            <div class="thumbnail-custom-body"><img src="images/media-2-325x166.jpg" alt="" width="325" height="166"/>
              <div class="caption">
                <div class="meta"><span class="fw-b"><a href="post.html">Ora Gray</a></span> | <span>
                            <time datetime="2016">20 Jul 2015</time></span>
                </div>
                <h5><a href="post.html">Romantic Wedding in French Alps</a></h5>
                <p class="small-2">Can there be a more romantic place to celebrate a wedding than French Alps? We doubt it. Especially considering how well established our Hotel is in organizing events of any scale or magnitude.</p>
              </div>
            </div>
            <div class="thumbnail-custom-footer">
              <div class="number">05</div><a href="post.html" class="btn btn-xs btn-primary btn-yellow-70">read more</a>
            </div>
          </div>
        </div>
        <div class="cell-lg-4 cell-sm-6 offset-top-30">
          <div class="thumbnail-custom thumbnail-custom-2">
            <div class="thumbnail-custom-body"><img src="images/media-3-325x166.jpg" alt="" width="325" height="166"/>
              <div class="caption">
                <div class="meta"><span class="fw-b"><a href="post.html">Kevin Baker</a></span> | <span>
                            <time datetime="2016">14 Jul 2015</time></span>
                </div>
                <h5><a href="post.html">Skiing Tours across Italian Alps</a></h5>
                <p class="small-2">Our proximity to Italian Alps lets us invite you for a skiing tour! So take your skiing backpack, and brace yourself for a perfect winter adventure!</p>
              </div>
            </div>
            <div class="thumbnail-custom-footer">
              <div class="number">06</div><a href="post.html" class="btn btn-xs btn-primary btn-yellow-70">read more</a>
            </div>
          </div>
        </div>
        <div class="cell-lg-4 cell-sm-6 offset-top-30">
          <div class="thumbnail-custom thumbnail-custom-2">
            <div class="thumbnail-custom-body"><img src="images/media-4-325x166.jpg" alt="" width="325" height="166"/>
              <div class="caption">
                <div class="meta"><span class="fw-b"><a href="post.html">Janie Miller</a></span> | <span>
                            <time datetime="2016">15 Jun 2015</time></span>
                </div>
                <h5><a href="post.html">Summer Wine in local French countryside.</a></h5>
                <p class="small-2">Sign up today for wine tasting of the new Bojole Nouveau of 2016! This celebrated wine canary is known for its light and fruity taste, so just experience all the frenchness of such a tour!</p>
              </div>
            </div>
            <div class="thumbnail-custom-footer">
              <div class="number">07</div><a href="post.html" class="btn btn-xs btn-primary btn-yellow-70">read more</a>
            </div>
          </div>
        </div>
        <div class="cell-xs-12 text-center offset-top-68">
          <!--Bootstrap Pagination-->
          <nav>
            <ul class="pagination-custom">
              <li><a href="#"><span class="mdi-arrow-bottom-left icon icon-white"></span></a></li>
              <li><a href="#">1</a></li>
              <li><a href="#">2</a></li>
              <li class="active"><span>3</span></li>
              <li><a href="#">4</a></li>
              <li><a href="#">5</a></li>
              <li><a href="#">6</a></li>
              <li><a href="#">7</a></li>
              <li><a href="#">8</a></li>
              <li><a href="#">9</a></li>
              <li class="dots"><span>...</span></li>
              <li><a href="#">45</a></li>
              <li><a href="#"><span class="mdi-arrow-bottom-right icon icon-white"></span></a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
