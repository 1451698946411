<app-header headerClass="header-3" [hasBookingDialog]="false"></app-header>
<main class="page-content bg-white">
    <section class="section-51 section-bottom-118">
      <div class="shell">
        <h2 i18n="rooms title|RoomsTitle@@roomsTitle">Rooms & Suites</h2>
        <div class="range offset-top-54">
          <div class="cell-lg-7">
            <div class="thumbnail-custom thumbnail-custom-3 thumbnail-custom-3-mod-1">
              <div class="thumbnail-custom-body">
                <!-- Owl Carousel-->
                <div data-items="1" data-stage-padding="0" data-loop="false" data-margin="30" data-mouse-drag="true" data-dots="true" class="owl-carousel owl-carousel-1">
                  <div class="owl-item"><img src="assets/images/cam5_2.jpg" alt="" width="625px" height="377px"/></div>
                  <div class="owl-item"><img src="assets/images/cam_7_5.jpg" alt=""/></div>
                  <div class="owl-item"><img src="assets/images/cam5_1.jpg" alt="" width="625px" height="377px"/></div>
                  <div class="owl-item"><img src="assets/images/cam8_baie2.jpeg" alt="" width="625px" height="377px"/></div>
                </div>
              </div>
              <div class="thumbnail-custom-footer">
                <div class="number">01</div><span class="fw-b h6 text-middle">cabanasmile.ro</span> <a routerLink="/contact" class="btn btn-md-2 btn-white" i18n="reservenow|Reserve Now Label@@reserveNowLabel">reserve now</a>
              </div>
            </div>
          </div>
          <div class="cell-lg-4">
            <div class="inset-lg-left-70 room-caption">
              <div class="range">
                <div class="cell-sm-6 cell-lg-12 offset-top-0">
                  <div class="title"><img src="assets/images/index-6-132x57.png" alt="" width="132" height="57" class="img-width-auto"/>
                    <div class="name">Family Room</div>
                    <!--<div class="available">Available</div>-->
                  </div>
                </div>
                <div class="cell-sm-6 cell-lg-12 offset-top-0">
                  <div class="description-2">
                    <h5 class="offset-md-top-43" i18n="apartmentsWith label|Apartments With@@aparmentsWithLabel">Apartments with:</h5>
                    <ul class="list-order-a list-order-a-mod-1">
                      <li i18n="Breakfast|Breakfast@@breakfastLabel">Breakfast</li>
                      <li i18n="Slope View|Slope View@@slopeViewLabel">Slope View</li>
                      <li i18n="Free WiFi|Free WiFi@@freeWifiLabel">Free WiFi</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="range offset-top-60">
          <div class="cell-lg-7">
            <div class="thumbnail-custom thumbnail-custom-3 thumbnail-custom-3-mod-1">
              <div class="thumbnail-custom-body">
                <!-- Owl Carousel-->
                <div data-items="1" data-stage-padding="0" data-loop="false" data-margin="30" data-mouse-drag="true" data-dots="true" class="owl-carousel owl-carousel-1">
                  <div class="owl-item"><img src="assets/images/cam7_7.jpg" alt="" width="625" height="377"/></div>
                  <div class="owl-item"><img src="assets/images/cam7_2.jpeg" alt="" width="625" height="377"/></div>
                  <div class="owl-item"><img src="assets/images/cam7_2.jpg" alt="" width="625" height="377"/></div>
                  <div class="owl-item"><img src="assets/images/cam7_4.jpg" alt="" width="625" height="377"/></div>
                  <div class="owl-item"><img src="assets/images/cam7_6.jpg" alt="" width="625" height="377"/></div>
                  <div class="owl-item"><img src="assets/images/cam8_1.jpeg" alt="" width="625" height="377"/></div>
                  <div class="owl-item"><img src="assets/images/cam8_baie.jpeg" alt="" width="625" height="377"/></div>
                  <div class="owl-item"><img src="assets/images/cam1_baie_1.jpeg" alt="" width="625" height="377"/></div>
                </div>
              </div>
              <div class="thumbnail-custom-footer">
                <div class="number">02</div><span class="fw-b h6 text-middle">cabanasmile.ro.</span><a routerLink="/contact" class="btn btn-md-2 btn-white" i18n="reservenow|Reserve Now Label@@reserveNowLabel">reserve now</a>
              </div>
            </div>
          </div>
          <div class="cell-lg-4">
            <div class="inset-lg-left-70 room-caption">
              <div class="range">
                <div class="cell-sm-6 cell-lg-12 offset-top-0">
                  <div class="title"><img src="assets/images/index-7-132x57.png" alt="" width="132" height="57" class="img-width-auto"/>
                    <div class="name">Double Room</div>
                    <!--<div class="available available-mod-1">Sorry, not available now</div>-->
                  </div>
                </div>
                <div class="cell-sm-6 cell-lg-12 offset-top-0">
                  <div class="description-2">
                    <h5 class="offset-md-top-43" i18n="apartmentsWith label|Apartments With@@aparmentsWithLabel">Apartments with:</h5>
                    <ul class="list-order-a list-order-a-mod-1">
                      <li i18n="Balcony with view|Balcony with view@@balconyWithViewLabel">Balcony with view</li>
                      <li i18n="Slope View|Slope View@@slopeViewLabel">Slope View</li>
                      <li i18n="Breakfast|Breakfast@@breakfastLabel">Breakfast</li>
                      <li i18n="Free WiFi|Free WiFi@@freeWifiLabel">Free WiFi</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
<app-footer></app-footer>
