<footer class="page-footer section-lg-141 section-50" style="background-color: #3b3b3b">
  <div class="shell">
    <div class="range">
      <div class="cell-lg-6 cell-sm-12">
        <!-- RD Navbar Brand-->
        <div class="rd-navbar-brand-footer"><a href="index.html" class="brand-name"><img
          src="assets/images/logo-1-103x143.png" alt="" width="103" height="143" class="logo-1"/></a>
          <p i18n="footer text1|Footer Text 1@@footerText1">Smile Apres Ski provides a wide array of summer and winter activities.</p>
          <p class="small" i18n="footer text2|Footer Text 2@@footerText2">For example, a winter season can offer diverse set of outdoor activities,
            if you find yourself in such a spectacular location as Straja. So you will be able
            to go on a downhill, snowboarding or sledging with your partner, friends or family. And
            during the hot French summer you will have an option to go hiking, bicycling or climbing
            across this beautiful countryside.</p>
        </div>
      </div>
      <div class="cell-lg-6 cell-sm-12 cell-sm-preffix-3 cell-lg-preffix-0 offset-sm-top-35 offset-lg-top-0">
        <div class="inset-lg-left-30">
          <p i18n="contact us|Contact us@@contactUsLabel">Contact us</p>
          <div class="reveal-inline-block offset-top-19">
            <p class="small" i18n="address label|Address@@addressLabel">Address:</p>
            <address class="address small offset-top-5" i18n="address text|Full location address@@addressText">Platoul Soarelui, Straja
            </address>
          </div>
          <div class="reveal-xs-inline-block pull-xs-right offset-top-19">
            <dl class="small">
              <dt class="fw-n" i18n="telephone label|Telephone@@telephoneLabel"> Telephone:</dt>
              <dd class="offset-top-5"><a href="callto:0040784776041">+40 (0)784 776 041</a></dd>
            </dl>
          </div>
          <ul class="list-inline offset-top-10">
            <li><a href="#" class="icon fa-facebook icon-white icon-sm"></a></li>
            <li><a href="#" class="icon fa-instagram icon-white icon-sm"></a></li>
          </ul>
          <!-- RD Google Map-->
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2805.472710299801!2d23.232196515552587!3d45.31907607909945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb79f4fbff3587f54!2sCabana+Smile!5e0!3m2!1sen!2sro!4v1532272636809"
            width="100%" height="220" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="range offset-lg-top-0 text-center text-lg-left">
      <div class="cell-xs-12">
        <p class="small">&#169; <span id="copyright-year"></span> SANDD SOFT WORKS All rights reserved.
        </p>
      </div>
    </div>
  </div>
</footer>
