<app-header headerClass="page-head bg-image bg-image-1 section-lg-bottom-320 header-default section-bottom-50" hasBookingDialog="true"></app-header>
<div class="bg-white">
<main class="page-content">
  <!--Section Our services Make your stay cool-->
  <section class="section-74">
    <div class="shell">
      <div class="range range-md-center">
        <div class="cell-md-6 text-md-center">
          <h2 class="txt-black line-height-26" i18n="@@makeYourStayCool">
            Make your stay cool
          </h2>
          <p class="txt-black offset-top-68" i18n>Our Pension is a perfect holiday
            destination both for the families with kids and for individuals. Our glorious
            mountainous landscapes will lighten your stay through all the seasons!</p>
        </div>
      </div>
      <div class="range">
        <div class="cell-md-12 section-top-17">
          <ol class="list-order-a range txt-black">
            <li class="cell-sm-3">
              <h4 i18n>Sport Activities</h4>
              <p i18n>Enjoy Mountain-Bike, Climbing, Hiking, Adventure Parks and other sports all in
                the proximity of our pension. Take all these and add Ski and Snowboard in the
                winter</p>
            </li>
            <li class="cell-sm-3">
              <h4 i18n>Accomodation</h4>
              <p i18n>We offer you the best conditions around. Pension was built in 2018 so everything
                is clean and new. With a capacity of 25 people it is more than enough for you
                and your friends to have the most comfortable stay in Straja</p>
            </li>
            <li class="cell-sm-3">
              <h4 i18n>Ski Courses</h4>
              <p i18n>Being here since we were little we learned winter sports very well. We offer ski
                and snowboard courses during your stay if you want to improve your skills on the
                slopes</p>
            </li>
            <li class="cell-sm-3">
              <h4 i18n>Food & Drinks</h4>
              <p i18n>We have a small kitchen where we can prepare your meals during your stay. If you
                want to have fun or just to heat your bones after all day on the slopes our Apres-Ski
                has everything you need</p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <!--Section Reviews-->
  <section class="section-bottom-65 section-lg-top-17 section-xs-17">
    <div class="shell">
      <div class="range range-md-center">
        <div class="cell-md-3 text-md-center">
          <h2 class="txt-black line-height-26" i18n>Reviews</h2>
        </div>
      </div>
      <div class="range range-md-center">
        <div class="cell-md-6">
          <!-- Owl Carousel-->
          <div data-items="1" data-stage-padding="15" data-loop="false" data-margin="70"
               data-dots="true" data-mouse-drag="true" class="owl-carousel">
            <div class="owl-item">
              <blockquote class="quote quote-2">
                <p>
                  <q i18n>Cosy place with nice hosts and good music</q>
                </p>
                <p>
                  <cite>Raul Jibotean<br><span>Romania</span></cite>
                </p>
              </blockquote>
            </div>
            <div class="owl-item">
              <blockquote class="quote quote-2">
                <p>
                  <q i18n>I enjoyed my time there so much, amazing persons</q>
                </p>
                <p>
                  <cite>Roxana Traista<br><span>Romania</span></cite>
                </p>
              </blockquote>
            </div>
            <div class="owl-item">
              <blockquote class="quote quote-2">
                <p>
                  <q i18n>Besides the great conditions and the clean rooms, the hosts are special.
                    Thank you for your warm welcome</q>
                </p>
                <p>
                  <cite>Florentina Barbulescu<br><span>Romania</span></cite>
                </p>
              </blockquote>
            </div>
            <div class="owl-item">
              <blockquote class="quote quote-2">
                <p>
                  <q i18n>Super! We'll definitely come back here! We felt great. I strongly recommend,
                    the hosts are 11/10.</q>
                </p>
                <p>
                  <cite>Ondina Ondi<br><span>Romania</span></cite>
                </p>
              </blockquote>
            </div>
            <div class="owl-item">
              <blockquote class="quote quote-2">
                <p>
                  <q i18n>Best location in Straja, special hosts!!!</q>
                </p>
                <p>
                  <cite>Denisa Marc<br><span>Romania</span></cite>
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</main>
<app-footer></app-footer>
</div>
