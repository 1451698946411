<app-header headerClass="header-3" [hasBookingDialog]="false"></app-header>
<div class="bg-image bg-image-1">
  <main class="page-content txt-white">
    <!--Hotel Photos and Videos-->
    <section class="txt-white margin-top-76 section-top-133 z-index-2">
      <gallery style="width: 100%" [flexBorderSize]="3" [flexImageSize]="7" [galleryName]=galleryName [maxRowsPerPage]="100">
      </gallery>
    </section>
  </main>
  <!-- Page Footer-->
  <app-footer></app-footer>
</div>
