<!-- Page Header-->
<header class="{{headerClass}}">
  <!-- RD Navbar-->
  <div class="rd-navbar-wrap">
    <nav data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fullwidth"
         data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static"
         data-lg-device-layout="rd-navbar-static" data-sm-stick-up-offset="50px" data-lg-stick-up-offset="150px"
         class="rd-navbar">
      <div class="rd-navbar-inner">
        <!-- RD Navbar Panel-->
        <div class="rd-navbar-panel">
          <!-- RD Navbar Toggle-->
          <button data-rd-navbar-toggle=".rd-navbar-nav-wrap" class="rd-navbar-toggle"><span></span>
          </button>
          <!-- RD Navbar Brand-->
          <div class="rd-navbar-brand"><a routerLink="/home" class="brand-name"><img
            src="assets/images/logo-1-103x143.png" alt="" width="103" height="143" class="logo-1"/><img
            src="assets/images/logo-2-125x56.png" alt="" width="125" height="56" class="logo-2"/><img
            src="assets/images/logo-3-125x56.png" alt="" width="125" height="56" class="logo-3"/><img
            src="assets/images/logo-4-102x123.png" alt="" width="102" height="123" class="logo-4"/></a>
          </div>
        </div>
        <div class="rd-navbar-nav-wrap">
          <!-- RD Navbar Nav-->
          <ul class="rd-navbar-nav">
            <li><a routerLink="/rooms" i18n="accomodations label|Accomodations@@accomodationLabel">Accommodations</a></li>
            <!--<li><a routerLink="/blog">Blog</a></li>-->
            <!--<li><a href="services.html">Services</a></li>-->
            <!--<li><a href="offers.html">Special Offers</a></li>-->
            <li><a routerLink="/gallery" i18n="gallery label|Gallery@@galleryLabel">Gallery</a></li>
            <li><a routerLink="/contact" i18n="contact label|Contact@@contactLabel">Contact</a></li>
          </ul>

          <ul class="rd-navbar-nav">
            <li [ngClass]="{active: selectedLocale=='ro'}"><a href="/ro/">RO</a></li>
            <li [ngClass]="{active: selectedLocale=='en'}"><a href="/en/">EN</a></li>
          </ul>

        </div>
      </div>
    </nav>
  </div>
  <app-header-reservation [hidden]="!hasBookingDialog"></app-header-reservation>
</header>
