import { AfterViewInit, Component } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'angular-smile';

  constructor(router: Router) {
    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        this.ngAfterViewInit()
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }

  ngAfterViewInit() {
    if(document.getElementById("testScript"))
      document.getElementById("testScript").remove();
    var testScript = document.createElement("script");
    testScript.setAttribute("id", "testScript");
    testScript.setAttribute("src", "assets/js/core.min.js");
    document.body.appendChild(testScript);

    if(document.getElementById("testScript2"))
      document.getElementById("testScript2").remove();
    var testScript = document.createElement("script");
    testScript.setAttribute("id", "testScript2");
    testScript.setAttribute("src", "assets/js/script.js");
    document.body.appendChild(testScript);
  }
}
