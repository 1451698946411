<section class="txt-white margin-top-85 section-top-60 section-lg-top-0">
  <div class="shell">
    <div class="range offset-top-17">
      <div class="cell-sm-6 cell-lg-3">
        <form class="form-search fadeInLeft animated">
          <div class="fadeIn animated">
            <div class="form-group date">
              <label for="datetimepicker1" class="form-label" i18n="arrival label|Arrival@@arrivalLabel">ARRIVAL</label>
              <input id="datetimepicker1" type="text" data-time-picker="date"
                     placeholder="2018    15 September" data-constraints="@Required"
                     class="form-control">
            </div>
            <div class="form-group date">
              <label for="datetimepicker2" class="form-label" i18n="departure label|Departure@@departureLabel">DEPARTURE</label>
              <input id="datetimepicker2" type="text" data-time-picker="date"
                     placeholder="2018    15 September" data-constraints="@Required"
                     class="form-control">
            </div>
            <div class="form-group">
              <label for="select2" class="form-label" i18n="rooms label|Rooms@@roomsLabel">ROOMS</label>
              <!--Select 2-->
              <select id="select2" data-placeholder="Standard Deluxe"
                      data-minimum-results-for-search="Infinity" data-constraints="@Required"
                      class="form-control select-filter">
                <option value="1">Double Room</option>
                <option value="2">Family Room</option>
              </select>
            </div>
            <div class="form-group form-group-stepper">
              <label for="qnt" class="form-label" i18n="adults label|Adults@@adultsLabel">Adults</label>
              <input id="qnt" type="number" name="qnt" value="1" min="1" max="4" readonly
                     class="form-control-2"><span class="max-value">/</span><span
              class="max-value">4</span>
            </div>
            <div class="form-group form-group-stepper">
              <label for="qnt2" class="form-label" i18n="children label|Children@@childrenLabel">Children</label>
              <input id="qnt2" type="number" name="qnt" value="0" min="0" max="4" readonly
                     class="form-control-2"><span class="max-value">/</span><span
              class="max-value">4</span>
            </div>
          </div>
          <a routerLink="/contact"><button class="btn btn-sm btn-primary btn-full offset-top-35" i18n="search label|Search@@searchLabel">search</button></a>
        </form>
      </div>
    </div>
  </div>
</section>
